import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)




export default new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/login',
            component: () => import ('@/views/login')
        },
        {
            path: '/',
            component: () => import('@/views/login')
        },
        {
            path: '/home',
            component: () => import('@/views/home')
        },
        {
            path: '/wind1',
            component: () => import('@/views/windMill/windMill.vue')
        },
        {
            path: '/electricity',
            component: () => import('@/views/Electricity')
        },
        {
            path: '/transaction',
            component: () => import('@/views/transaction/')
        },
    ]
})


