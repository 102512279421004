import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import dataV from '@jiaminghi/data-view'

Vue.use(dataV)

Vue.config.productionTip = false
Vue.use(ElementUI);

new Vue({
    el: '#app',
    router,
  render: h => h(App),
}).$mount('#app') 
